<template>
  <div :class="`message-card ${isMe ? 'isMe' : 'isUser'}`">
    <div class="message-card-date-group" v-if="eachDate(getDate(item.date))">
      <div class="date">{{ $global.dateLocaleFormat(getDate(item.date)) }}</div>
    </div>
    <div class="message-card-content">
      <div class="message-card-message">
        <MessagesChatCardFiles :message="item" :post="item.post" v-if="item.files.length || item.post" />
        <div class="message-card-text" v-html="decodeURIComponent(item.message)"></div>
        <MessagesChatCardPrice :message="item" :confirmUnlock="confirmUnlock" v-if="item.price" />
        <div class="message-card-message-date">
          <div v-if="item.processing" class="MessageCardDateLoading">
            <iconic name="gspinner" />
            <span>{{ $locale["sending_message"] }}</span>
          </div>
          <span v-else v-html="getTime(item.date)"></span>
        </div>
        <MessagesChatCardUnlock :message="item" :confirmUnlock="confirmUnlock" v-if="unlockConfirm" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    MessagesChatCardFiles: () => import("./MessagesChatCardFiles.vue"),
    MessagesChatCardPrice: () => import("./MessagesChatCardPrice.vue"),
    MessagesChatCardUnlock: () => import("./MessagesChatCardUnlock.vue"),
  },
  props: ["item", "eachDate"],
  data: function() {
    return {
      unlockConfirm: false,
    };
  },
  methods: {
    getTime: function(date) {
      let time = date.split("T");
      time = time[1].split(".")[0];
      return this.$global.timeAMPM(time);
    },
    getDate: function(date) {
      let time = date.split("T");
      return time[0];
    },
    confirmUnlock: function() {
      this.unlockConfirm = !this.unlockConfirm;
    },
  },
  computed: {
    isMe: function() {
      return (this.item.isMe && this.item.processing) || this.item.from._id === this.$user.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-card {
  position: relative;
  &-content {
    position: relative;
    display: flex;
    align-items: center;
  }
  &-message {
    position: relative;
    padding: $mpadding/1.5;
    border-radius: $mpadding/1.5;
    background-color: #fff;
    margin-bottom: $mpadding;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    max-width: 380px;
    overflow: hidden;
    &-text {
      word-break: break-word;
    }
    &-date {
      font-size: 80%;
      margin: $mpadding/2 0 0 0;
      opacity: 0.8;
      display: flex;
      align-items: center;
      gap: $mpadding/2;
    }
  }
  &.isMe &-content {
    justify-content: flex-end;
  }
  &.isMe &-message {
    background-color: $secondary_color_saturate;
    border-bottom-right-radius: 0;
  }
  &.isMe &-message-date {
    text-align: right;
    justify-content: flex-end;
  }
  &.isUser &-content {
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
  &.isUser &-message {
    background-color: #f8f8f8;
    border-bottom-left-radius: 0;
  }
  &-date-group {
    text-align: center;
    font-size: 90%;
    opacity: 0.8;
    margin: $mpadding 0 $mpadding * 2 0;
    .date {
      display: inline-block;
      position: relative;
      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: $mpadding * 2;
        height: 2px;
        background-color: $emperor;
        opacity: 0.6;
      }
      &::before {
        left: -$mpadding * 3;
      }
      &::after {
        right: -$mpadding * 3;
      }
    }
  }

  .MessageCardDateLoading {
    @include Flex(inherit, center, center);
    gap: $mpadding/2;
  }
}
</style>
